import { IStrategy } from '@navix/utils/adapter'
import { GenericItem } from '@navix/ui/common'
import { GetReportsResponse } from '../domain/get-reports.response'

export class FromGetReportsResponse
  implements IStrategy<GetReportsResponse, GenericItem[]>
{
  convert(response: GetReportsResponse): GenericItem[] {
    return response.value.map(permission => ({
      id: permission.ReportId.toString(),
      name: permission.Name
    }))
  }
}
