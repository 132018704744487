import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { createReducer, on, Action } from '@ngrx/store'

import { Role } from '../domain/role.model'
import { RolesLoading } from '../domain/roles-loading.model'
import { DataFilters } from '../domain/data-filters.model'
import { rolesActions } from './roles.actions'
import { GenericItem } from '@navix/ui/common'

export const ROLES_FEATURE_KEY = 'feature-roles'

export interface RolesState extends EntityState<Role> {
  totalCount: number
  loading: RolesLoading
  filters: DataFilters
  selectedId?: number
  users: GenericItem[]
  reports: GenericItem[]
  permissions: GenericItem[]
}

export interface RolesPartialState {
  readonly [ROLES_FEATURE_KEY]: RolesState
}

export const rolesAdapter: EntityAdapter<Role> = createEntityAdapter<Role>()

export const initialRolesState: RolesState = rolesAdapter.getInitialState({
  totalCount: 0,
  users: [],
  reports: [],
  permissions: [],
  loading: {
    getAll: false,
    getDetails: false,
    getPermissions: false,
    getReports: false,
    getUsers: false
  },
  filters: {
    search: undefined,
    page: 1,
    itemsPerPage: 25,
    sortBy: 'Name',
    sortDirection: 'asc'
  }
})

const reducer = createReducer(
  initialRolesState,
  on(
    rolesActions.loadRolesSuccess,
    (state, { roles }): RolesState => rolesAdapter.setAll(roles, state)
  ),
  on(
    rolesActions.loadUsersSuccess,
    (state, { users }): RolesState => ({
      ...state,
      users
    })
  ),
  on(
    rolesActions.loadReportsSuccess,
    (state, { reports }): RolesState => ({
      ...state,
      reports
    })
  ),
  on(
    rolesActions.loadPermissionsSuccess,
    (state, { permissions }): RolesState => ({
      ...state,
      permissions
    })
  ),
  on(
    rolesActions.setTotalCount,
    (state, { count }): RolesState => ({
      ...state,
      totalCount: count
    })
  ),
  on(
    rolesActions.setLoading,
    (state, { operation, loading }): RolesState => ({
      ...state,
      loading: { ...state.loading, [operation]: loading }
    })
  ),
  on(
    rolesActions.setListFilters,
    (state, { filters }): RolesState => ({
      ...state,
      filters
    })
  ),
  on(
    rolesActions.loadRoleDetails,
    (state, { roleId }): RolesState => ({ ...state, selectedId: roleId })
  ),
  on(
    rolesActions.setSelectedId,
    (state, { selectedId }): RolesState => ({ ...state, selectedId })
  ),
  on(
    rolesActions.loadRoleDetailsSuccess,
    (state, { role }): RolesState => rolesAdapter.upsertOne(role, state)
  )
)

export function rolesReducer(state: RolesState | undefined, action: Action) {
  return reducer(state, action)
}
