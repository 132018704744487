export enum AsyncOperations {
  getAll = 'getAll',
  getDetails = 'getDetails',
  getPermissions = 'getPermissions',
  getReports = 'getReports',
  getUsers = 'getUsers'
}

export type RolesLoading = {
  [key in AsyncOperations]: boolean
}
