import { GetRolesResponse } from '../domain/get-roles.response'
import { Role } from '../domain/role.model'
import { IStrategy } from '@navix/utils/adapter'

export class FromGetRolesResponse
  implements IStrategy<GetRolesResponse, Role[]>
{
  convert(response: GetRolesResponse): Role[] {
    return response.value
      .map(
        role =>
          ({
            id: role.RoleId,
            name: role.Name,
            permissionsCount: role.PermissionsCount,
            reportsCount: role.ReportsCount,
            usersCount: role.UsersCount
          }) as Role
      )
      .toSorted((previous, next) => previous.name.localeCompare(next.name))
  }
}
