import { GenericItem } from '@navix/ui/common'
import { GetUsersResponse } from '../domain/get-users.response'
import { IStrategy } from '@navix/utils/adapter'

export class FromGetUsersResponse
  implements IStrategy<GetUsersResponse, GenericItem[]>
{
  convert(response: GetUsersResponse): GenericItem[] {
    return response.value.map(user => ({
      id: user.Uuid,
      name: user.Email
    }))
  }
}
