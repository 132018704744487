import { createFeatureSelector, createSelector } from '@ngrx/store'
import { ROLES_FEATURE_KEY, RolesState, rolesAdapter } from './roles.reducer'

export const selectRolesState =
  createFeatureSelector<RolesState>(ROLES_FEATURE_KEY)

const { selectAll, selectEntities } = rolesAdapter.getSelectors()

export const selectAllRoles = createSelector(
  selectRolesState,
  (state: RolesState) => selectAll(state)
)

export const selectRolesEntities = createSelector(
  selectRolesState,
  (state: RolesState) => selectEntities(state)
)

export const selectTotalCount = createSelector(
  selectRolesState,
  (state: RolesState) => state.totalCount
)

export const selectLoading = createSelector(
  selectRolesState,
  (state: RolesState) => state.loading
)

export const selecFilters = createSelector(
  selectRolesState,
  (state: RolesState) => state.filters
)

export const selectSelectedId = createSelector(
  selectRolesState,
  (state: RolesState) => state.selectedId
)

export const selectEntity = createSelector(
  selectRolesEntities,
  selectSelectedId,
  (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
)

export const selectAllUsers = createSelector(
  selectRolesState,
  (state: RolesState) => state.users
)

export const selectAllReports = createSelector(
  selectRolesState,
  (state: RolesState) => state.reports
)

export const selectAllPermissions = createSelector(
  selectRolesState,
  (state: RolesState) => state.permissions
)
