import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Role } from '../domain/role.model'
import { AsyncOperations } from '../domain/roles-loading.model'
import { DataFilters } from '../domain/data-filters.model'
import { RoleForm } from '../domain/role-form.model'
import { GenericItem } from '@navix/ui/common'

export const rolesActions = createActionGroup({
  source: 'Roles',
  events: {
    'load roles': props<{ filters: DataFilters }>(),
    'load roles success': props<{ roles: Role[] }>(),
    'load roles fail': props<{ error: unknown }>(),

    'load users': emptyProps(),
    'load users success': props<{ users: GenericItem[] }>(),
    'load reports': emptyProps(),
    'load reports success': props<{ reports: GenericItem[] }>(),
    'load permissions': emptyProps(),
    'load permissions success': props<{ permissions: GenericItem[] }>(),

    'load role details': props<{ roleId: number }>(),
    'load role details success': props<{ role: Role }>(),
    'load role details fail': props<{ error: unknown }>(),

    'add role': props<{ role: RoleForm }>(),
    'add role success': emptyProps(),
    'add role fail': props<{ error: string }>(),

    'update role': props<{ roleId: number; role: RoleForm }>(),
    'update role success': emptyProps(),
    'update role fail': props<{ error: string }>(),

    'delete role': props<{ roleId: number }>(),
    'delete role success': emptyProps(),
    'delete role fail': props<{ error: string }>(),

    'set total count': props<{ count: number }>(),
    'set list filters': props<{ filters: DataFilters }>(),

    'set loading': props<{ operation: AsyncOperations; loading: boolean }>(),
    'set selected id': props<{ selectedId: number }>()
  }
})
