import { IStrategy } from '@navix/utils/adapter'
import { GetPermissionsResponse } from '../domain/get-permissions.response'
import { GenericItem } from '@navix/ui/common'

export class FromGetPermissionsResponse
  implements IStrategy<GetPermissionsResponse, GenericItem[]>
{
  convert(response: GetPermissionsResponse): GenericItem[] {
    return response
      .map(permission => ({
        id: permission.permissionId,
        name: permission.permissionName
      }))
      .sort((a, b) => a.name.localeCompare(b.name))
  }
}
