import { Dialog } from '@angular/cdk/dialog'
import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { LegacyAlertsFacade } from '@navix/alerts/domain'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import {
  catchError,
  exhaustMap,
  finalize,
  map,
  of,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs'
import { FromGetPermissionsResponse } from '../adapter/FromGetPermissionsResponse'
import { FromGetReportsResponse } from '../adapter/FromGetReportsResponse'
import { FromGetRoleResponse } from '../adapter/FromGetRoleResponse'
import { FromGetRolesResponse } from '../adapter/FromGetRolesResponse'
import { FromGetUsersResponse } from '../adapter/FromGetUsersResponse'
import { ToGenericItemAdapter, ToRoleAdapter } from '../adapter/RoleAdapter'
import { AsyncOperations } from '../domain/roles-loading.model'
import { RolesService } from '../infrastructure/roles.service'
import { rolesActions } from './roles.actions'
import {
  DELETE_ROLE_DIALOG_ID,
  ROLES_ABSOLUTE_ROUTE_PREFIX
} from './roles.constants'
import { RolesFacade } from './roles.facade'

@Injectable()
export class RolesEffects {
  private alertsFacade = inject(LegacyAlertsFacade)
  private rolesFacade = inject(RolesFacade)
  private rolesService = inject(RolesService)
  private actions$ = inject(Actions)
  private router = inject(Router)
  private dialog = inject(Dialog)

  private toRoleAdapter = new ToRoleAdapter()
  private toGenericItemAdapter = new ToGenericItemAdapter()

  private readonly ROLE_IN_USE_ERROR_MESSAGE = 'Role Name in use'
  private readonly CANNOT_DELETE_ERROR_MESSAGE =
    'Role cannot be deleted. Remove associations first.'

  constructor() {
    this.toRoleAdapter.set(FromGetRolesResponse)
    this.toRoleAdapter.set(FromGetRoleResponse)
    this.toGenericItemAdapter.set(FromGetUsersResponse)
    this.toGenericItemAdapter.set(FromGetPermissionsResponse)
    this.toGenericItemAdapter.set(FromGetReportsResponse)
  }

  loadRoles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.loadRoles),
      tap(() => this.rolesFacade.startLoading(AsyncOperations.getAll)),
      exhaustMap(({ filters }) =>
        this.rolesService.getRoles(filters).pipe(
          map(response => ({
            roles: this.toRoleAdapter.convert(FromGetRolesResponse, response),
            totalCount: response['@odata.count']
          })),
          switchMap(response => [
            rolesActions.loadRolesSuccess({ roles: response.roles }),
            rolesActions.setTotalCount({ count: response.totalCount })
          ]),
          catchError(error => of(rolesActions.loadRolesFail({ error }))),
          finalize(() => this.rolesFacade.endLoading(AsyncOperations.getAll))
        )
      )
    )
  )

  loadRolesFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rolesActions.loadRolesFail),
        tap(error => {
          console.log(error)
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load roles."
          })
        })
      ),
    { dispatch: false }
  )

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.loadUsers),
      tap(() => this.rolesFacade.startLoading(AsyncOperations.getUsers)),
      exhaustMap(() =>
        this.rolesService.getUsers().pipe(
          map(response => ({
            users: this.toGenericItemAdapter.convert(
              FromGetUsersResponse,
              response
            )
          })),
          switchMap(response => [
            rolesActions.loadUsersSuccess({ users: response.users })
          ]),
          finalize(() => this.rolesFacade.endLoading(AsyncOperations.getUsers))
        )
      )
    )
  )

  loadReports$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.loadReports),
      tap(() => this.rolesFacade.startLoading(AsyncOperations.getReports)),
      exhaustMap(() =>
        this.rolesService.getReports().pipe(
          map(response =>
            this.toGenericItemAdapter.convert(FromGetReportsResponse, response)
          ),
          switchMap(reports => [rolesActions.loadReportsSuccess({ reports })]),
          finalize(() =>
            this.rolesFacade.endLoading(AsyncOperations.getReports)
          )
        )
      )
    )
  )

  loadPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.loadPermissions),
      tap(() => this.rolesFacade.startLoading(AsyncOperations.getPermissions)),
      exhaustMap(() =>
        this.rolesService.getPermissions().pipe(
          map(response => ({
            permissions: this.toGenericItemAdapter.convert(
              FromGetPermissionsResponse,
              response
            )
          })),
          switchMap(response => [
            rolesActions.loadPermissionsSuccess({
              permissions: response.permissions
            })
          ]),
          finalize(() =>
            this.rolesFacade.endLoading(AsyncOperations.getPermissions)
          )
        )
      )
    )
  )

  loadRoleDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.loadRoleDetails),
      tap(() => this.rolesFacade.startLoading(AsyncOperations.getDetails)),
      switchMap(({ roleId }) => this.rolesService.getRoleDetails(roleId)),
      map(res => this.toRoleAdapter.convert(FromGetRoleResponse, res)),
      map(role => rolesActions.loadRoleDetailsSuccess({ role })),
      catchError(error => of(rolesActions.loadRoleDetailsFail({ error }))),
      tap(() => this.rolesFacade.endLoading(AsyncOperations.getDetails))
    )
  )

  loadRoleDetailsFail$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rolesActions.loadRolesFail),
        tap(error => {
          console.log(error)
          this.alertsFacade.addAlert({
            alertType: 'danger',
            label: "There's an error trying to load role details."
          })
        })
      ),
    { dispatch: false }
  )

  addRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.addRole),
      map(({ role }) => role),
      exhaustMap(request =>
        this.rolesService.addRole(request).pipe(
          map(() => rolesActions.addRoleSuccess()),
          catchError(({ error }) => of(rolesActions.addRoleFail({ error })))
        )
      )
    )
  )

  addRoleOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.addRoleSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Role has been added successfully.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.router.navigateByUrl(`${ROLES_ABSOLUTE_ROUTE_PREFIX}/list`)
      ),
      withLatestFrom(this.rolesFacade.filters$),
      switchMap(([, filters]) => of(rolesActions.loadRoles({ filters })))
    )
  )

  addRoleOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rolesActions.addRoleFail),
        tap(({ error }) => {
          if (error === this.ROLE_IN_USE_ERROR_MESSAGE) {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: 'The role name is already in use.'
            })
          } else {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: "There's an error trying to create role."
            })
          }
        })
      ),
    { dispatch: false }
  )

  updateRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.updateRole),
      map(({ roleId, role }) => ({ roleId, role })),
      exhaustMap(({ roleId, role }) =>
        this.rolesService.updateRole(roleId, role).pipe(
          map(() => rolesActions.updateRoleSuccess()),
          catchError(({ error }) => of(rolesActions.updateRoleFail({ error })))
        )
      )
    )
  )

  updateRoleOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.updateRoleSuccess),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Role has been updated successfully.`,
          alertType: 'success'
        })
      }),
      tap(() =>
        this.router.navigateByUrl(`${ROLES_ABSOLUTE_ROUTE_PREFIX}/list`)
      ),
      withLatestFrom(this.rolesFacade.filters$),
      switchMap(([, filters]) => of(rolesActions.loadRoles({ filters })))
    )
  )

  updateRoleOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rolesActions.updateRoleFail),
        tap(({ error }) => {
          if (error === this.ROLE_IN_USE_ERROR_MESSAGE) {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: 'The role name is already in use.'
            })
          } else {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: "There's an error trying to update role."
            })
          }
        })
      ),
    { dispatch: false }
  )

  deleteRole$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.deleteRole),
      map(({ roleId }) => roleId),
      exhaustMap((roleId: number) =>
        this.rolesService.deleteRole(roleId).pipe(
          map(() => rolesActions.deleteRoleSuccess()),
          catchError(({ error }) => of(rolesActions.deleteRoleFail({ error })))
        )
      )
    )
  )

  deleteRoleOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rolesActions.deleteRoleSuccess),
      tap(() => this.dialog.getDialogById(DELETE_ROLE_DIALOG_ID)?.close()),
      tap(() => {
        this.alertsFacade.addAlert({
          label: `Role has been deleted successfully.`,
          alertType: 'success'
        })
      }),
      withLatestFrom(this.rolesFacade.filters$),
      switchMap(([, filters]) => of(rolesActions.loadRoles({ filters })))
    )
  )

  deleteRoleOnError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rolesActions.deleteRoleFail),
        tap(({ error }) => {
          if (error === this.CANNOT_DELETE_ERROR_MESSAGE) {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: 'Role cannot be deleted. Remove associations first.'
            })
          } else {
            this.alertsFacade.addAlert({
              alertType: 'danger',
              label: "There's an error trying to remove role."
            })
          }
        })
      ),
    { dispatch: false }
  )
}
