import { Injectable, inject } from '@angular/core'
import { select, Store } from '@ngrx/store'
import { firstValueFrom } from 'rxjs'
import * as RolesSelector from './roles.selectors'
import { rolesActions } from './roles.actions'
import { DataFilters } from '../domain/data-filters.model'
import { AsyncOperations } from '../domain/roles-loading.model'
import { RoleForm } from '../domain/role-form.model'

@Injectable()
export class RolesFacade {
  private readonly store: Store = inject(Store)

  selectedRole$ = this.store.pipe(select(RolesSelector.selectEntity))
  allRoles$ = this.store.pipe(select(RolesSelector.selectAllRoles))
  allUsers$ = this.store.pipe(select(RolesSelector.selectAllUsers))
  allReports$ = this.store.pipe(select(RolesSelector.selectAllReports))
  allPermissions$ = this.store.pipe(select(RolesSelector.selectAllPermissions))
  totalCount$ = this.store.pipe(select(RolesSelector.selectTotalCount))
  loading$ = this.store.pipe(select(RolesSelector.selectLoading))
  filters$ = this.store.pipe(select(RolesSelector.selecFilters))

  loadRoles(filters: DataFilters) {
    this.store.dispatch(rolesActions.loadRoles({ filters }))
  }

  loadUsers() {
    this.store.dispatch(rolesActions.loadUsers())
  }

  loadReports() {
    this.store.dispatch(rolesActions.loadReports())
  }

  loadPermissions() {
    this.store.dispatch(rolesActions.loadPermissions())
  }

  addRole(role: RoleForm) {
    this.store.dispatch(rolesActions.addRole({ role }))
  }

  updateRole(roleId: number, role: RoleForm) {
    this.store.dispatch(rolesActions.updateRole({ roleId, role }))
  }

  deleteRole(roleId: number) {
    this.store.dispatch(rolesActions.deleteRole({ roleId }))
  }

  selectRole(roleId: number) {
    this.store.dispatch(rolesActions.loadRoleDetails({ roleId }))
  }

  startLoading(operation: AsyncOperations) {
    this.store.dispatch(
      rolesActions.setLoading({
        operation,
        loading: true
      })
    )
  }

  setSelectedId(selectedId: number) {
    this.store.dispatch(rolesActions.setSelectedId({ selectedId }))
  }

  endLoading(operation: AsyncOperations) {
    this.store.dispatch(
      rolesActions.setLoading({
        operation,
        loading: false
      })
    )
  }

  async updateListFilters(filters: DataFilters) {
    const currentFilters = await firstValueFrom(this.filters$)
    const newFilters = { ...currentFilters, ...(filters ?? {}) }
    this.store.dispatch(rolesActions.setListFilters({ filters: newFilters }))
  }
}
