import { makeEnvironmentProviders } from '@angular/core'
import { provideState } from '@ngrx/store'
import { ROLES_FEATURE_KEY, rolesReducer } from '../+state/roles.reducer'
import { RolesEffects } from '../+state/roles.effects'
import { provideEffects } from '@ngrx/effects'
import { RolesFacade } from '../+state/roles.facade'

export function provideRolesFeature() {
  return makeEnvironmentProviders([
    provideEffects(RolesEffects),
    provideState(ROLES_FEATURE_KEY, rolesReducer),
    RolesFacade
  ])
}
