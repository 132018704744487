import { GetRoleResponse } from '../domain/get-role.response'
import { IStrategy } from '@navix/utils/adapter'
import { Role } from '../domain/role.model'

export class FromGetRoleResponse implements IStrategy<GetRoleResponse, Role> {
  convert(response: GetRoleResponse): Role {
    const role = response.value[0]
    return {
      id: role.RoleId,
      name: role.Name,
      permissions: role.RolePermissions.flatMap(permission =>
        permission.PermissionId.toString()
      ),
      reports: role.RoleReports.flatMap(report => report.ReportId.toString()),
      users: role.RoleUsers.flatMap(permission => permission.UserUuid)
    } as Partial<Role> as Role
  }
}
