import { Injectable, inject } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { API_URL, HEADER_API_KEY } from '@navix/utils/tokens'
import { OdataParser, QUERY_PARSER } from '@navix/utils/query-parser'
import { GetRolesResponse } from '../domain/get-roles.response'
import { Observable } from 'rxjs'
import { DataFilters } from '../domain/data-filters.model'
import { GetRoleResponse } from '../domain/get-role.response'
import { GetUsersResponse } from '../domain/get-users.response'
import { GetPermissionsResponse } from '../domain/get-permissions.response'
import { RoleForm } from '../domain/role-form.model'
import { GetReportsResponse } from '../domain/get-reports.response'

@Injectable({
  providedIn: 'root'
})
export class RolesService {
  private readonly _http = inject(HttpClient)
  private readonly apiUrl = inject(API_URL)
  private readonly apiKey = inject(HEADER_API_KEY)
  private readonly parser = inject<OdataParser>(QUERY_PARSER)

  private readonly apiAuthUrl = `${this.apiUrl}auth` as const
  private filterColumns = ['Name']

  getRoles(filters: DataFilters): Observable<GetRolesResponse> {
    const query = this.parser
      .prepareWithoutEntity()
      .setCount(true)
      .setContainFilterColumns(this.filterColumns)
      .setSelect([
        'RoleId',
        'Name',
        'PermissionsCount',
        'UsersCount',
        'ReportsCount'
      ])

    if (filters.search) query.setContainFilter(filters.search)
    if (filters.itemsPerPage) query.setTop(filters.itemsPerPage)
    if (filters.itemsPerPage && filters.page)
      query.setSkip(filters.itemsPerPage * (filters.page - 1))
    if (filters.sortBy && filters.sortDirection)
      query.setOrderBy({
        column: filters.sortBy,
        direction: filters.sortDirection
      })
    return this._http.get<GetRolesResponse>(`${this.apiAuthUrl}/roles`, {
      params: query.getQueryParamsAsHttpParams(),
      headers: this.apiKey
    })
  }

  getUsers(): Observable<GetUsersResponse> {
    const query = this.parser
      .prepareWithoutEntity()
      .setSelect(['Uuid', 'Email'])
      .setOrderBy({ column: 'Email', direction: 'asc' })

    return this._http.get<GetUsersResponse>(`${this.apiUrl}users-list`, {
      params: query.getQueryParamsAsHttpParams(),
      headers: this.apiKey
    })
  }

  getReports(): Observable<GetReportsResponse> {
    const query = this.parser
      .prepareWithoutEntity()
      .setSelect(['ReportId', 'Name'])
      .setOrderBy({ column: 'Name', direction: 'asc' })

    return this._http.get<GetReportsResponse>(`${this.apiAuthUrl}/reports`, {
      params: query.getQueryParamsAsHttpParams(),
      headers: this.apiKey
    })
  }

  getPermissions(): Observable<GetPermissionsResponse> {
    return this._http.get<GetPermissionsResponse>(
      `${this.apiAuthUrl}/permissions`,
      { headers: this.apiKey }
    )
  }

  getRoleDetails(roleId: number): Observable<GetRoleResponse> {
    return this._http.get<GetRoleResponse>(
      `${this.apiAuthUrl}/roles?$filter=RoleId eq ${roleId}`,
      { headers: this.apiKey }
    )
  }

  addRole(request: RoleForm): Observable<object> {
    return this._http.post(`${this.apiAuthUrl}/roles`, request, {
      headers: { ...this.apiKey }
    })
  }

  updateRole(roleId: number, request: RoleForm): Observable<object> {
    return this._http.put(`${this.apiAuthUrl}/roles/${roleId}`, request, {
      headers: { ...this.apiKey }
    })
  }

  deleteRole(roleId: number): Observable<object> {
    return this._http.delete(`${this.apiAuthUrl}/roles/${roleId}`, {
      headers: { ...this.apiKey }
    })
  }
}
